/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/sensorineural/'], ['en', 'https://www.hear.com/hearing-loss/sensorineural/'], ['en-US', 'https://www.hear.com/hearing-loss/sensorineural/'], ['en-CA', 'https://ca.hear.com/hearing-loss/sensorineural/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "symptoms-diagnosis--treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-diagnosis--treatment",
    "aria-label": "symptoms diagnosis  treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms, diagnosis & treatment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sensorineural hearing loss (also called Labyrinthine hearing loss) is a functional impairment of the hearing organ (sound-sensing hair cells) as well as a dysfunctional neural component which can lead to hearing impairment (hypoacusis). Its severity may range from slight comprehension difficulties to complete hearing loss or deafness. The early provision of hearing aids helps relieve these symptoms and improve hearing capacity."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The selection of a hearing aid is always tailored to the degree of personal hearing loss and individual requirements. hear.com can assist you in finding the right hearing aids, and will support you during the entire fitting process."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-of-sensorineural-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-of-sensorineural-hearing-loss",
    "aria-label": "causes of sensorineural hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes of sensorineural hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are several causes of sensorineural hearing loss. One frequent cause is age-associated hearing loss, which can begin at the age of 50 or 60. This type of hearing loss is usually accompanied by the reduced perception of high tones."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The second most common cause is noise. Six to eight hours of noise above 85dB on a daily basis is able to cause this type of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Additional reasons for sensorineural hearing loss include circulatory problems, as they may occur in diabetes mellitus, arteriosclerosis, or sudden hearing loss, as well as metabolic illnesses, such as thyroid malfunction."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-of-sensorineural-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-of-sensorineural-hearing-loss",
    "aria-label": "symptoms of sensorineural hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms of sensorineural hearing loss"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/occupational-illness.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "People who experience sensorineural hearing loss complain of limited speech intelligibility. In its early stages, intelligibility is initially limited to background noises. Depending on severity, individuals with this kind of hearing loss may also ", React.createElement(_components.a, {
    href: "/resources/useful-knowledge/tinnitus-causes-symptoms-possible-solutions/",
    className: "c-md-a"
  }, "suffer from buzzing or ringing in the ears (tinnitus)"), "."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Significant risks posed by persistent sensorineural hearing loss include social isolation and psychological changes. Therefore, prompt treatment is highly recommended.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosis",
    "aria-label": "diagnosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/sensorineural-hearing-loss.jpg",
    alt: "hearing loss",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ENT specialist diagnoses sensorineural (or labyrinthine) hearing loss through a serie of tests. A mirror examination is initially performed on both ears to exclude causes originating in the external ear canal. In purely sensorineural hearing loss, the mirror examination is normal. Further diagnostics can also include a hearing test (audiometry)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During impedance audiometry, the vibrational ability of the eardrums and ossicles are examined, and the pressure in the middle ear is measured. This examination serves to exclude impaired middle ear function (sensorineural hearing loss)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Pure-tone audiometry examines the patient’s tone perception. Tones of varying frequency and increasing volume are then presented to the patient via headphones. The patient signals as soon as he perceives the tone. This creates an acoustic curve, on the basis of which deviations from the standard can be used to classify the type and extent of hearing loss. In people with sensorineural hearing loss, this acoustic curve does not present any difference between bone conduction (tones are transmitted through the bone) and the air duct (tones are transmitted through the air to the eardrum and to the inner ear via the ossicles). Both curves are positioned in higher decibel ranges compared to those of healthy patients."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Other important tests include the Weber test and the Rinne test. Bone and air conduction is tested during these two exams. In the case of labyrinthine hearing loss, the Weber test will ascertain that the tone relocates to the healthy ear, i.e. where it is perceived as louder."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An additional method is the measurement of otoacoustic emissions. This method involves sending a signal to the patient’s ear and measuring the reaction of the inner ear’s external hair cells. This test does not result in measureable emissions if the hair cells are damaged."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment--prognosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment--prognosis",
    "aria-label": "treatment  prognosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment & Prognosis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Currently, there are neither medicinal nor surgical treatments for sensorineural hearing loss. Prompt provision of a suitable hearing aid is therefore recommended. These days, hearing systems work with cutting-edge digital technology, processing sound signals in small processors (similar to tiny computers). Thanks to this modern technology, these devices are able to adjust to ambient conditions and to distinguish a person’s voice from noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Labyrinthine or sensorineural hearing loss can remain constant for years or continuously and slowly deteriorate. This is frequently not perceived by the patient and thus harbors the risk of social isolation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To prevent social consequences for the patient, the prompt provision of a suitable hearing aid is recommended."), "\n", React.createElement(ButtonCta, {
    copy: "Improve your hearing now!",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
